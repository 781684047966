import { Models } from '@roberty/models'

import RestClient from './rest'

const AuthenticationService = new class {
  private user: Models.User
  private partners: Pick<Models.Partner, "_id" | "displayName">[]

  async check() {
    try {
      const response = await RestClient.Authorized.get()
      this.user = response.user
      this.partners = response.partners
      return true
    } catch (error) {
      console.error(error)
      this.redirectToAuthentication()
      return false
    }
  }

  getUser() {
    return this.user
  }

  getPartner(id: string) {
    return this.partners.find(p => p._id === id)
  }

  private redirectToAuthentication() {
    const currentURL = location.href
    const authURL = new URL(`/?next=${currentURL}`, process.env.REACT_APP_AUTH_URL)
    location.href = authURL.href
  }
}

export default AuthenticationService