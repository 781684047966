import React, { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"
import clsx from "clsx"

import styles from "./accountSwap.module.scss"

import swapIcon from "./icons/swap.svg"
import i18n from "../../i18n"
import { LayoutContext } from "../layout/layout.context"
import PartnerAvatar from "../avatar/partner"
import AuthenticationService from "src/services/authentication"

const AccountSwap: React.FC = () => {
  const { partnerId } = useParams()
  const layoutContext = useContext(LayoutContext)
  const partner = AuthenticationService.getPartner(partnerId)
  return (
    <Link to="/">
      <div className={clsx({
        [styles.accountSwap]: true,
        [styles.compact]: layoutContext.compactMenu,
      })}>
        <PartnerAvatar
          name={partner?.displayName}
        />
        <div className={styles.workspaceInfo}>
          <strong>{partner?.displayName}</strong>
          <span>{i18n["menu.swapSubtitle"]}</span>
        </div>
        <ReactSVG
          className={styles.swapIcon}
          src={swapIcon}
          wrapper="span"
        />
      </div>
    </Link>
  )
}

export default AccountSwap