import { useHookstate } from '@hookstate/core'
import { Breadcrumb, Button, Tabs, TabsProps, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import WorkspaceAvatar from 'src/components/avatar/workspace'
import Layout from 'src/components/layout'
import Loading from 'src/components/loading'
import presentConnectionErrorMessage from 'src/lib/presentConnectionErrorMessage'
import RestClient from 'src/services/rest'

import styles from './index.module.scss'
import { LocalState } from './state'

const WorkspacePage: React.FC = () => {
  const { partnerId, workspaceId } = useParams()
  const state = useHookstate(LocalState)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    RestClient.Workspace.get(partnerId, workspaceId)
      .then((workspace) => {
        state.workspace.set(workspace)
        state.loading.set(false)
      })
      .catch((error) => {
        presentConnectionErrorMessage(error)
        navigate(`/${partnerId}/workspaces`)
      })
  }, [])

  useEffect(() => {
    const tab = location.pathname.split("/")[4]
    if (tab) state.activeTab.set(tab)
    else navigate("overview")
  }, [location])

  if (state.loading.get()) return <Loading />

  return (
    <Layout currentMenu="workspaces">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/${partnerId}/workspaces`}>
            {`< Workspaces`}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <h1 className={styles.title}>
            {state.workspace.displayName?.get()}
          </h1>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.workspaceInfo}>
        <WorkspaceAvatar
          workspaceId={state.workspace._id?.get() as string}
          name={state.workspace.displayName?.get()}
          size={72}
        />
        <div className={styles.details}>
          <h2>{state.workspace.displayName?.get()}</h2>
          <small>Member since {moment(state.workspace._createdBy?.when?.get()).format("LL")}</small>
        </div>
        <Tooltip title="Coming soon">
          <Button
            size="large"
            disabled={true}
          >
            View workspace on studio
          </Button>
        </Tooltip>
      </div>
      <Tabs
        activeKey={state.activeTab.get()}
        onChange={state.activeTab.set}
        items={tabs}
      />
      <div className={styles.outlet}>
        <Outlet />
      </div>
    </Layout>
  )
}

const tabs: TabsProps[`items`] = [
  {
    key: "overview",
    label: <Link to="overview">Usage</Link>
  },
  {
    key: "robots",
    label: <Link to="robots">Robots</Link>
  },
  {
    key: "devices",
    label: <Link to="devices">Devices</Link>
  },
  {
    key: "users",
    label: <Link to="users">Users</Link>
  },
  {
    key: "edit",
    label: <Link to="edit">Edit workspace</Link>
  }
]

export default WorkspacePage