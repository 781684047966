import { Enums } from '@roberty/models'

function getFeatureName(feature: Enums.SubscriptionConsumableFeatures) {
  switch (feature) {
    case "CAPTCHA_RESOLVE":
      return "Captcha requests"
    case "EMAIL_SENDING":
      return "E-mails"
    case "LINKED_DEVICES":
      return "Devices"
    case "RUN_SECONDS":
      return "Robot execution time (in seconds)"
    case "SMS_SENDING":
      return "SMS"
    case "TEXTRACT_CALL":
      return "OCR + IA requests"
    default:
      return (feature as any).toString()
  }
}

export default getFeatureName