import React from "react"
import { Button, Col, Row } from "antd"

import styles from "./index.module.scss"
import Layout from "../../components/layout"
import RealtimeRunning from "../../components/realtime/running"
import RealtimeErrors from "../../components/realtime/errors"
import RealtimeNext from "../../components/realtime/next"
import i18n from "../../i18n"

const RealtimePage: React.FC = () => {
  return (
    <Layout currentMenu="realtime">
      <Row justify="space-between">
        <Col>
          <h1 className={styles.title}>
            {i18n["realtime.title"]}
          </h1>
        </Col>
        <Col>
          <Button type="primary">
            {i18n["realtime.tvMode.enter"]}
          </Button>
        </Col>
      </Row>
      <section>
        <h2 className={styles.title}>
          {i18n["realtime.running.title"]}
        </h2>
        <RealtimeRunning />
      </section>
      <section>
        <h2 className={styles.title}>
          {i18n["realtime.next.title"]}
        </h2>
        <RealtimeNext />
      </section>
      <section>
        <h2 className={styles.title}>
          {i18n["realtime.errors.title"]}
        </h2>
        <RealtimeErrors />
      </section>
    </Layout>
  )
}

export default RealtimePage
