import { useEffect, useState } from "react"
import { useWindowSize } from "usehooks-ts"

function useMobile() {
  const [isMobile, setIsMobile] = useState(false)
  const size = useWindowSize()

  useEffect(() => {
    const isMobile = size.width < 1200
    setIsMobile(isMobile)
  }, [size])

  return isMobile
}

export default useMobile