import React, { useEffect, useState } from "react"
import { Models } from "@roberty/models"
import moment from "moment"
import { useParams } from "react-router-dom"
import { Col, Row } from "antd"

import styles from "./next.module.scss"
import presentConnectionErrorMessage from "../../lib/presentConnectionErrorMessage"
import RestClient from "../../services/rest"
import Loading from "../loading"
import formatDuration from "../../lib/formatDuration"
import WorkspaceAvatar from "../avatar/workspace"
import StatisticsCard from "../statisticsCard"
import i18n from "../../i18n"

const RealtimeNext: React.FC = () => {
  const { partnerId } = useParams()
  const [loading, setLoading] = useState(true)
  const [robots, setRobots] = useState<NextRobotsResponse>([])

  useEffect(() => {
    fetchNext(partnerId)
      .then(setRobots)
      .finally(() => setLoading(false))
    const loop = setInterval(() => {
      fetchNext(partnerId)
        .then(setRobots)
    }, 30000)
    return () => {
      clearInterval(loop)
    }
  }, [])

  if (loading) return (
    <Loading />
  )

  return (
    <div className={styles.next}>
      <Row gutter={16}>
        {robots.map(data => (
          <Col key={data.robot._id as string} xl={6} lg={8} xs={24}>
            <NextRunInfo
              key={data.robot._id as string}
              workspace={data.workspace}
              robot={data.robot}
              nextRunDate={data.nextRunDate}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}

const NextRunInfo: React.FC<NextRunInfoProps> = (props) => {
  const [nextRunDate, setNextRunDate] = useState("...")
  const [startingSoon, setStartingSoon] = useState(false)
  const [delayed, setDelayed] = useState(false)

  useEffect(() => {
    const loop = setInterval(() => {
      const diff = moment(props.nextRunDate).diff(moment(), "seconds")
      setNextRunDate(formatDuration(diff))
      if (diff < 1) setStartingSoon(true)
      if (diff < -60) setDelayed(true)
    }, 1000)
    return () => {
      clearInterval(loop)
    }
  }, [])

  return (
    <StatisticsCard
      data={startingSoon
        ? i18n["realtime.next.startingSoon"]
        : nextRunDate
      }
      title={<>
        <span>{props.robot.name}</span>
        <br />
        <small>{i18n["realtime.next.startingIn"]}</small>
      </>}
      variation={delayed
        ? i18n["realtime.next.delayed"]
        : undefined
      }
      variationColor={delayed ? "var(--pink)" : undefined}
    >
      <div className={styles.workspace}>
        <WorkspaceAvatar
          workspaceId={props.workspace._id as string}
          name={props.workspace.displayName}
          size="small"
        />
        <small>
          {props.workspace.displayName}
        </small>
      </div>
      <small>
        {i18n["realtime.next.nextRunDate"](props.nextRunDate)}
      </small>
    </StatisticsCard>
  )
}

type NextRunInfoProps = {
  workspace: Pick<Models.Customer, "_id" | "displayName">,
  robot: Pick<Models.Robot, "_id" | "name">,
  nextRunDate: Date,
}

type NextRobotsResponse = Awaited<ReturnType<typeof fetchNext>>

async function fetchNext(partnerId: string) {
  try {
    return await RestClient.Realtime.Next.get(partnerId)
  } catch (error) {
    presentConnectionErrorMessage()
    return []
  }
}

export default RealtimeNext