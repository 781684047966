import React, { useContext, useEffect, useState } from "react"

import styles from "./mainMenu.module.scss"
import billingIcon from "./icons/billing.svg"
import customersIcon from "./icons/customers.svg"
import dashboardIcon from "./icons/dashboard.svg"
import homeIcon from "./icons/home.svg"
import notificationIcon from "./icons/notification.svg"
import realtimeIcon from "./icons/realtime.svg"
import settingIcon from "./icons/setting.svg"
import supportIcon from "./icons/support.svg"

import { LayoutContext } from "../layout/layout.context"
import i18n from "../../i18n"
import AccountSwap from "./accountSwap"
import MenuItems, { MenuItemsProps } from "./menuItems"
import DesktopMainMenu from "./desktop"
import MobileMainMenu from "./mobile"
import useMobile from "../../hooks/useMobile"

const MainMenu: React.FC<MainMenuProps> = (props) => {
  const layoutContext = useContext(LayoutContext)
  const mobile = useMobile()

  useEffect(() => {
    layoutContext.setCurrentMenu(props.currentMenu)
  }, [props.currentMenu])

  if (mobile) return (
    <MobileMainMenu>
      <MenuItems items={topItems} />
      <MenuItems items={bottomItems} />
      <AccountSwap />
    </MobileMainMenu>
  )

  return (
    <DesktopMainMenu>
      <MenuItems items={topItems} />
      <div className={styles.spacer}></div>
      <MenuItems items={bottomItems} />
      <AccountSwap />
    </DesktopMainMenu>
  )
}

type MainMenuProps = {
  currentMenu: string
}

const topItems: MenuItemsProps["items"] = [
  // {
  //   id: "home",
  //   name: i18n["menu.home"],
  //   path: "/:partnerId",
  //   icon: homeIcon
  // },
  {
    id: "dashboard",
    name: i18n["menu.dashboard"],
    path: "/:partnerId/dashboard",
    icon: dashboardIcon
  },
  {
    id: "workspaces",
    name: i18n["menu.workspaces"],
    path: "/:partnerId/workspaces",
    icon: customersIcon
  },
  {
    id: "realtime",
    name: i18n["menu.realtimeMonitor"],
    path: "/:partnerId/realtime",
    icon: realtimeIcon
  }
]

const bottomItems: MenuItemsProps["items"] = [
  // {
  //   id: "notifications",
  //   name: i18n["menu.notifications"],
  //   path: "/:partnerId/notifications",
  //   icon: notificationIcon,
  //   soon: true
  // },
  {
    id: "billing",
    name: i18n["menu.billing"],
    path: "/:partnerId/billing",
    icon: billingIcon
  },
  {
    id: "support",
    name: i18n["menu.support"],
    path: "/:partnerId/support",
    icon: supportIcon,
    soon: true
  },
  {
    id: "settings",
    name: i18n["menu.settings"],
    path: "/:partnerId/settings",
    icon: settingIcon
  },
]

export default MainMenu