import { Address } from '@roberty/models/src/types/Address'
import { Col, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import presentConnectionErrorMessage from 'src/lib/presentConnectionErrorMessage'

import styles from './addressForm.module.scss'

const AddressForm: React.FC<AddressFormProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [cep, setCep] = useState("")
  const [logradouro, setLogradouro] = useState("")
  const [numero, setNumero] = useState("")
  const [complemento, setComplemento] = useState("")
  const [bairro, setBairro] = useState("")
  const [cidade, setCidade] = useState("")
  const [uf, setUf] = useState("")

  useEffect(() => {
    if (!props.onChange) return
    props.onChange({
      address: logradouro,
      city: cidade,
      country: "Brazil",
      district: bairro,
      number: numero,
      state: uf,
      zipCode: cep,
      complement: complemento
    })
  }, [
    cep,
    logradouro,
    numero,
    complemento,
    bairro,
    cidade,
    uf
  ])

  const searchForAddress = (cep: string) => {
    if (!cep.trim()) return
    setLoading(true)
    fetchCep(cep)
      .then(address => {
        setLogradouro(address.logradouro)
        setBairro(address.bairro)
        setCidade(address.localidade)
        setUf(address.uf)
      })
      .catch(() => {
        presentConnectionErrorMessage("Zip code not found or invalid.")
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className={styles.addressForm}>
      <Row gutter={16}>
        <Col lg={4}>
          <label htmlFor="cep">CEP:</label>
          <Input
            name="cep"
            value={cep}
            onChange={e => setCep(e.target.value)}
            onBlur={e => searchForAddress(e.target.value)}
          />
        </Col>
        <Col lg={12}>
          <label htmlFor="logradouro">Logradouro:</label>
          <Input
            name="logradouro"
            value={logradouro}
            onChange={e => setLogradouro(e.target.value)}
          />
        </Col>
        <Col lg={2}>
          <label htmlFor="numero">Numero:</label>
          <Input
            name="numero"
            value={numero}
            onChange={e => setNumero(e.target.value)}
          />
        </Col>
        <Col lg={6}>
          <label htmlFor="complemento">Complemento:</label>
          <Input
            name="complemento"
            value={complemento}
            onChange={e => setComplemento(e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={9}>
          <label htmlFor="bairro">Bairro:</label>
          <Input
            name="bairro"
            value={bairro}
            disabled={loading}
            onChange={e => setBairro(e.target.value)}
          />
        </Col>
        <Col lg={9}>
          <label htmlFor="cidade">Cidade:</label>
          <Input
            name="cidade"
            value={cidade}
            disabled={loading}
            onChange={e => setCidade(e.target.value)}
          />
        </Col>
        <Col lg={6}>
          <label htmlFor="estado">Estado:</label>
          <Select
            value={uf}
            disabled={loading}
            onChange={setUf}
            style={{ width: "100%" }}
          >
            {estados.map((estado) => (
              <Select.Option
                key={estado.sigla}
                value={estado.sigla}
              >
                {estado.nome}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  )
}

type AddressFormProps = {
  onChange?: (address: Address) => void
}

async function fetchCep(cep: string) {
  const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
  const json = await response.json() as ViacepResponse
  return json
}

type ViacepResponse = {
  logradouro: string,
  bairro: string,
  localidade: string,
  uf: string,
}

const estados = [
  { nome: "Acre", sigla: "AC" },
  { nome: "Alagoas", sigla: "AL" },
  { nome: "Amapá", sigla: "AP" },
  { nome: "Amazonas", sigla: "AM" },
  { nome: "Bahia", sigla: "BA" },
  { nome: "Ceará", sigla: "CE" },
  { nome: "Distrito Federal", sigla: "DF" },
  { nome: "Espírito Santo", sigla: "ES" },
  { nome: "Goiás", sigla: "GO" },
  { nome: "Maranhão", sigla: "MA" },
  { nome: "Mato Grosso", sigla: "MT" },
  { nome: "Mato Grosso do Sul", sigla: "MS" },
  { nome: "Minas Gerais", sigla: "MG" },
  { nome: "Pará", sigla: "PA" },
  { nome: "Paraíba", sigla: "PB" },
  { nome: "Paraná", sigla: "PR" },
  { nome: "Pernambuco", sigla: "PE" },
  { nome: "Piauí", sigla: "PI" },
  { nome: "Rio de Janeiro", sigla: "RJ" },
  { nome: "Rio Grande do Norte", sigla: "RN" },
  { nome: "Rio Grande do Sul", sigla: "RS" },
  { nome: "Rondônia", sigla: "RO" },
  { nome: "Roraima", sigla: "RR" },
  { nome: "Santa Catarina", sigla: "SC" },
  { nome: "São Paulo", sigla: "SP" },
  { nome: "Sergipe", sigla: "SE" },
  { nome: "Tocantins", sigla: "TO" },
]

export default AddressForm