import { Models } from "@roberty/models"
import { Col, Row, Tag, Table } from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import StatisticsCard from "src/components/statisticsCard"
import presentConnectionErrorMessage from "src/lib/presentConnectionErrorMessage"
import RestClient from "src/services/rest"

const WorkspaceRobots: React.FC = () => {
  const { partnerId, workspaceId } = useParams()
  const [loading, setLoading] = useState(true)
  const [dataSource, setDataSource] = useState<Models.Robot[]>([])
  useEffect(() => {
    fetchRobots(partnerId, workspaceId)
      .then(setDataSource)
      .catch(presentConnectionErrorMessage)
      .finally(() => setLoading(false))
  }, [])
  return (
    <>
      <section>
        <Row>
          <Col xs={24} lg={8} xl={6}>
            <StatisticsCard
              title="Total robots"
              data={dataSource.length}
              loading={loading}
            />
          </Col>
        </Row>
      </section>
      <section>
        <Table
          rowKey={record => record._id as string}
          dataSource={dataSource}
          loading={loading}
          columns={[
            {
              title: "Name",
              render: (record: Models.Robot) => (
                <div>{record.name}</div>
              )
            },
            {
              title: "Status",
              render: (record: Models.Robot) => record.active
                ? <Tag color="var(--green)">Active</Tag>
                : <Tag color="var(--pink)">Inactive</Tag>
            },
          ]}
          pagination={{ defaultPageSize: 20 }}
        />
      </section>
    </>
  )
}

async function fetchRobots(partnerId: string, workspaceId: string) {
  return await RestClient.Workspace.Robots.get(partnerId, workspaceId)
}

export default WorkspaceRobots