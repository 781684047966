import moment from "moment"
import I18nMessages from "../messages"

const ptBR: I18nMessages = {
  "app.title": "Roberty",
  "app.copyright": `Copyright © ${new Date().getFullYear()} Roberty Automation. Todos os direitos reservados.`,
  "app.connectionError": "Houve um erro ao conectar aos nossos servidores. Tente novamente mais tarde 🙁",
  "common.back": "Voltar",
  "common.cancel": "Cancelar",
  "common.close": "Fechar",
  "common.confirm": "Confirmar",
  "common.delete": "Excluir",
  "common.edit": "Editar",
  "common.save": "Salvar",
  "common.search": "Pesquisar",
  "common.yes": "Sim",
  "common.no": "Não",
  "common.next": "Próximo",
  "common.previous": "Anterior",
  "common.loading": "Carregando...",
  "common.soon": "Em breve",
  "menu.home": "Página inicial",
  "menu.dashboard": "Dashboard",
  "menu.workspaces": "Workspaces",
  "menu.realtimeMonitor": "Monitoramento",
  "menu.notifications": "Notificações",
  "menu.billing": "Faturamento",
  "menu.support": "Suporte",
  "menu.settings": "Configurações",
  "menu.swapSubtitle": "Portal do parceiro",
  "realtime.title": "Monitoramento em tempo real",
  "realtime.tvMode.enter": "Entrar no modo TV",
  "realtime.tvMode.exit": "Sair do modo TV",
  "realtime.running.title": "Executando agora",
  "realtime.running.empty": "Nenhuma execução em andamento",
  "realtime.running.inProgress": "Em execução",
  "realtime.running.takingLonger": "Demorando muito",
  "realtime.running.stop": "Interromper",
  "realtime.running.averageDuration": "Duração média",
  "realtime.next.title": "Próximas execuções",
  "realtime.next.empty": "Nenhuma execução agendada nas próximas 24 horas",
  "realtime.next.startingIn": "Iniciando em",
  "realtime.next.startingSoon": "Iniciando",
  "realtime.next.delayed": "Atrasado",
  "realtime.next.nextRunDate": (nextRunDate: Date): string => `Inicia ${moment(nextRunDate).isSame(moment().add(1, "day"), "day") ? "amanhã às" : "às"} ${moment(nextRunDate).format("HH:mm")}`,
  "realtime.errors.title": "Erros não resolvidos",
  "realtime.errors.empty": "Nenhum erro",
  "realtime.errors.robot.title": "Robô",
  "realtime.errors.errorInfo.title": "Informações do erro",
  "realtime.errors.actions.title": "Ações",
  "realtime.errors.type": "Tipo",
  "realtime.errors.duration": "Duração",
  "realtime.errors.failedAt": "Falhou há",
  "realtime.errors.lastRunAt": "Última execução há",
  "realtime.errors.outputButton": "Output",
  "realtime.errors.screenshotButton": "Screenshot",
  "realtime.errors.markAsSolved": (amount?: number) => `Marcar${amount ? ` ${amount}` : ""} como resolvido`,
  "realtime.errors.markAsSolved.confirm": (amount: number) => `Tem certeza que deseja marcar ${amount} como resolvido?`,
}

export default ptBR