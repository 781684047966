import { Enums } from '@roberty/models'
import { Button, Form, Input, message, Modal, Select } from 'antd'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import presentConnectionErrorMessage from 'src/lib/presentConnectionErrorMessage'
import RestClient from 'src/services/rest'

import styles from './invite.module.scss'

const defaultRole = "ADMIN" as Enums.PartnerUserAuthorizationRole

const SettingsInviteUserPage: React.FC = () => {
  const { partnerId } = useParams()
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const [open] = useState(true)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [role, setRole] = useState(defaultRole)

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    inviteUser(partnerId, email, role)
      .then(() => {
        message.success("User is now able to access this account 🥳")
        navigate(`/${partnerId}/settings/users`)
      })
      .catch((error) => {
        presentConnectionErrorMessage(error)
        setLoading(false)
      })
  }

  return (
    <Modal
      open={open}
      title="Invite user"
      onCancel={() => navigate(`/${partnerId}/settings/users`)}
      onOk={form.submit}
      footer={null}
    >
      <Form
        form={form}
        onSubmitCapture={onSubmit}
      >
        <small className={styles.disclaimer}>
          The user must already have an account on Roberty to be invited.
        </small>
        <label htmlFor="email">
          E-mail:
        </label>
        <Input
          id="email"
          type="email"
          required={true}
          autoFocus={true}
          value={email}
          placeholder="email@example.com"
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
        />
        <br />
        <br />
        <label htmlFor="role">
          Role:
        </label>
        <Select
          id="role"
          disabled={loading}
          style={{ width: "100%" }}
          value={role}
          onChange={setRole}
        >
          <Select.Option value="ADMIN">Admin</Select.Option>
          <Select.Option value="DEVELOPER">Developer</Select.Option>
          <Select.Option value="BILLING">Billing</Select.Option>
        </Select>
        <br />
        <br />
        <Button
          type="primary"
          loading={loading}
          htmlType="submit"
        >
          Invite
        </Button>
      </Form>
    </Modal>
  )
}

async function inviteUser(partnerId: string, email: string, role: Enums.PartnerUserAuthorizationRole) {
  await RestClient.Authorization.post(partnerId, email, role)
}

export default SettingsInviteUserPage