import { Models } from '@roberty/models'
import { Button, Col, Input, notification, Row, Table, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import WorkspaceAvatar from 'src/components/avatar/workspace'

import Layout from '../../components/layout'
import StatisticsCard from '../../components/statisticsCard'
import objectIdToDate from '../../lib/objectIdToDate'
import RestClient from '../../services/rest'
import styles from './index.module.scss'

const WorkspacesPage: React.FC = () => {
  const { partnerId } = useParams()
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [keyword, setKeyword] = useState(``)
  const [pageSize, setPageSize] = useState(5)
  const [activeWorkspaces, setActiveWorkspaces] = useState(0)
  const [workspaces, setWorkspaces] = useState<Models.Customer[]>([])

  const fetchWorkspaces = async () => {
    setLoading(true)
    try {
      const { list, totalCount, activeCount } = await RestClient.Workspaces.get({
        partnerId,
        keyword,
        page,
        pageSize
      })
      setWorkspaces(list)
      setTotal(totalCount)
      setActiveWorkspaces(activeCount)
    } catch (e) {
      notification.error({
        message: `Erro ao carregar workspaces!`,
        description: `Tente novamente...`
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => { fetchWorkspaces() }, [page, pageSize])

  return (
    <Layout currentMenu="workspaces">
      <Row justify="space-between">
        <Col>
          <h1 className={styles.title}>Workspaces</h1>
        </Col>
        <Col>
          <Link to="create">
            <Button
              type="primary"
              size="large"
            >
              Create workspace
            </Button>
          </Link>
        </Col>
      </Row>
      <section>
        <Row gutter={16}>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <StatisticsCard
              title="Total workspaces"
              data={total}
              loading={loading}
            />
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <StatisticsCard
              title="Active workspaces"
              data={activeWorkspaces}
              variationType="none"
              loading={loading}
            />
          </Col>
          <Col xl={6} lg={8} sm={12} xs={24}>
            <StatisticsCard
              title="More stats coming"
              data="Soon"
              disabled={true}
            />
          </Col>
        </Row>
      </section>
      <section>
        <Row gutter={20} justify={`space-between`}>
          <Col />
          <Col>
            <Input.Search
              loading={loading}
              size={`large`}
              onSearch={fetchWorkspaces}
              onChange={({ target: { value } }) => setKeyword(value)}
              value={keyword}
              placeholder={`Filtrar workspaces...`}
            />
          </Col>
        </Row>
        <br />
        <Table
          rowKey={(record: Models.Customer) => record._id as string}
          columns={[
            {
              title: "Workspace",
              render: (record: Models.Customer) => (
                <Link
                  className={styles.workspaceColumn}
                  to={`${record._id}`}
                >
                  <WorkspaceAvatar
                    workspaceId={record._id as string}
                    name={record.displayName}
                  />
                  <div className={styles.workspaceInfo}>
                    <strong>{record.displayName}</strong>
                    <small>Member since {objectIdToDate(record._id as string).toLocaleDateString()}</small>
                  </div>
                </Link>
              )
            },
            {
              title: "Status",
              render: (record: Models.Robot) => record.active
                ? <Tag color="var(--green)">Active</Tag>
                : <Tag color="var(--pink)">Inactive</Tag>
            },
          ]}
          dataSource={workspaces}
          loading={loading}
          pagination={{
            pageSize,
            current: page,
            total,
            onChange: (page, pageSize) => {
              setPage(page)
              setPageSize(pageSize)
            }
          }}
        />
      </section>
    </Layout>
  )
}

export default WorkspacesPage