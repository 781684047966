import React, { useEffect, useState } from "react"
import { Avatar, AvatarProps } from "antd"

const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const [src, setSrc] = useState<string | undefined>(props.src)

  useEffect(() => {
    if (!props.src) {
      setSrc(`https://ui-avatars.com/api/?name=${props.name}&background=random`)
    }
  }, [props])

  return (
    <Avatar
      src={src}
      {...props}
    />
  )
}

type UserAvatarProps = AvatarProps & {
  src?: string
  name: string
}

export default UserAvatar