import { useHookstate } from '@hookstate/core'
import { Enums } from '@roberty/models'
import { Button, Col, Divider, Form, Input, Row, Select, Switch, Typography } from 'antd'
import React from 'react'

import { getFormattedFederalDocument, getFormattedZipCode } from '../../../lib/formattedPatterns'
import { useControllers } from './controllers'
import { LocalState } from './state'

const TypeOptions: {
  label: string,
  value: Enums.CustomerType
}[] = [
    {
      label: "Business",
      value: Enums.CustomerType["BUSINESS"]
    },
    {
      label: "Person",
      value: Enums.CustomerType["PERSON"]
    }
  ]


const WorkspaceEdit: React.FC = () => {
  const controllers = useControllers()
  const state = useHookstate(LocalState)

  return (
    <div>
      <Row gutter={20} justify={`space-between`}>
        <Col />
        <Col>
          <Button
            type={`primary`}
            disabled={!controllers.canSave()}
            onClick={controllers.handleSave()}
          >
            {`Update workspace`}
          </Button>
        </Col>
      </Row>
      <Form layout={"vertical"}>
        <Typography.Title level={4} type={`secondary`}>
          {`Basic Info`}
        </Typography.Title>
        <Divider style={{ margin: `1rem 0` }} />
        <Row gutter={20}>
          <Col flex={"1"}>
            <Form.Item
              label={"Status"}
              required
            >
              <Row gutter={10}>
                <Col>
                  <Switch
                    checked={state.workspace.active?.get()}
                    onChange={state.workspace.active?.set}
                  />
                </Col>
                <Col>
                  <label>{`Active`}</label>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col flex={"1"}>
            <Form.Item
              label={"Type"}
              required
            >
              <Select
                options={TypeOptions}
                placeholder={"Required"}
                value={state.workspace.type?.get()}
                onChange={state.workspace.type?.set}
              />
            </Form.Item>
          </Col>
          <Col flex={"2"}>
            <Form.Item
              label={"Name"}
              required
              help={state.errors.nested(`displayName`).get()}
              validateStatus={state.errors.nested(`displayName`).get() ? `error` : ``}
            >
              <Input
                inputMode={"text"}
                placeholder={"Required"}
                onChange={({ target: { value } }) => state.workspace.displayName?.set(value)}
                value={state.workspace.displayName?.get()}
              />
            </Form.Item>
          </Col>
          <Col flex={"2"}>
            <Form.Item
              label={"E-mail"}
              help={state.errors.nested(`email`).get()}
              validateStatus={state.errors.nested(`email`).get() ? `error` : ``}
            >
              <Input
                inputMode={"email"}
                onChange={({ target: { value } }) => state.workspace.email?.set(value)}
                value={state.workspace.email?.get()}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col flex={"1"}>
            <Form.Item
              label={"Legal name"}
              required
              help={state.errors.nested(`legalName`).get()}
              validateStatus={state.errors.nested(`legalName`).get() ? `error` : ``}
            >
              <Input
                inputMode={"text"}
                placeholder={"Required"}
                onChange={({ target: { value } }) => state.workspace.legalName?.set(value)}
                value={state.workspace.legalName?.get()}
              />
            </Form.Item>
          </Col>
          <Col flex={"1"}>
            <Form.Item
              label={"Federal document"}
              required
              help={state.errors.nested(`federalDocument`).get()}
              validateStatus={state.errors.nested(`federalDocument`).get() ? `error` : ``}
            >
              <Input
                inputMode={"numeric"}
                placeholder={"Required"}
                maxLength={18}
                onChange={({ target: { value } }) => state.workspace.federalDocument?.set(value)}
                value={getFormattedFederalDocument(state.workspace.federalDocument?.get())}
              />
            </Form.Item>
          </Col>
        </Row>
        <Typography.Title level={4} type={`secondary`}>
          {`Address`}
        </Typography.Title>
        <Divider style={{ margin: `1rem 0` }} />
        <Row gutter={20}>
          <Col flex={"1"}>
            <Form.Item
              label={"Zip code"}
              help={state.errors.nested(`address.zipCode`).get()}
              validateStatus={state.errors.nested(`address.zipCode`).get() ? `error` : ``}
            >
              <Input
                maxLength={9}
                inputMode={"numeric"}
                placeholder={"Required"}
                disabled={state.loadingZipCode.get()}
                onChange={({ target: { value } }) => state.workspace.address.zipCode.set(value)}
                value={getFormattedZipCode(state.workspace.address?.zipCode?.get())}
              />
            </Form.Item>
          </Col>
          <Col flex={"2"}>
            <Form.Item
              label={"Address"}
            >
              <Input
                inputMode={"text"}
                placeholder={"Required"}
                disabled={state.loadingZipCode.get()}
                onChange={({ target: { value } }) => state.workspace.address.address.set(value)}
                value={state.workspace.address?.address?.get()}
              />
            </Form.Item>
          </Col>
          <Col flex={"1"}>
            <Form.Item
              label={"Number"}
            >
              <Input
                inputMode={"text"}
                placeholder={"Required"}
                onChange={({ target: { value } }) => state.workspace.address.number.set(value)}
                value={state.workspace.address?.number?.get()}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col flex={"1"}>
            <Form.Item
              label={"Complement"}
            >
              <Input
                inputMode={"text"}
                onChange={({ target: { value } }) => state.workspace.address.complement.set(value)}
                value={state.workspace.address?.complement?.get()}
              />
            </Form.Item>
          </Col>
          <Col flex={"1"}>
            <Form.Item
              label={"District"}
            >
              <Input
                inputMode={"text"}
                placeholder={"Required"}
                disabled={state.loadingZipCode.get()}
                onChange={({ target: { value } }) => state.workspace.address.district.set(value)}
                value={state.workspace.address?.district?.get()}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col flex={"1"}>
            <Form.Item
              label={"City"}
            >
              <Input
                inputMode={"text"}
                placeholder={"Required"}
                disabled={state.loadingZipCode.get()}
                onChange={({ target: { value } }) => state.workspace.address.city.set(value)}
                value={state.workspace.address?.city?.get()}
              />
            </Form.Item>
          </Col>
          <Col flex={"1"}>
            <Form.Item
              label={"State"}
            >
              <Input
                inputMode={"text"}
                placeholder={"Required"}
                disabled={state.loadingZipCode.get()}
                onChange={({ target: { value } }) => state.workspace.address.state.set(value)}
                value={state.workspace.address?.state?.get()}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default WorkspaceEdit