import React, { useContext } from "react"
import { Col, List, Row, Select } from "antd"

import styles from "./preferences.module.scss"
import DarkModeService from "src/services/darkMode"
import { LayoutContext } from "src/components/layout/layout.context"
import useMobile from "src/hooks/useMobile"
import LocaleService from "src/services/locale"

const SettingsPreferencesPage: React.FC = () => {
  const layoutContext = useContext(LayoutContext)
  const isMobile = useMobile()
  const [theme, setTheme] = React.useState<"light" | "dark" | "auto">(DarkModeService.get())
  const [language] = React.useState(LocaleService.get())

  const onThemeChange = (value: "light" | "dark" | "auto") => {
    DarkModeService.set(value)
    setTheme(value)
  }

  const onLanguageChange = (value: "en-US" | "pt-BR") => {
    if (!confirm("Are you sure you want to change the language?")) return
    LocaleService.set(value)
    location.reload()
  }

  return (
    <div className={styles.preferences}>
      <section>
        <List
          bordered={true}
          header={(
            <h2 className={styles.title}>
              Appearance
            </h2>
          )}
        >
          <List.Item>
            <Row
              style={{ width: "100%" }}
              justify="space-between"
              align="middle"
            >
              <Col>Theme</Col>
              <Col>
                <Select
                  value={theme}
                  onChange={onThemeChange}
                  style={{ width: 120 }}
                >
                  <Select.Option value="light">
                    Light
                  </Select.Option>
                  <Select.Option value="dark">
                    Dark
                  </Select.Option>
                  <Select.Option value="auto">
                    Auto ({DarkModeService.getBrowserDefault()})
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </List.Item>
          <List.Item>
            <Row
              style={{ width: "100%" }}
              justify="space-between"
              align="middle"
              hidden={isMobile}
            >
              <Col>Compact menu</Col>
              <Col>
                <Select
                  value={layoutContext.compactMenu}
                  onChange={layoutContext.setCompactMenu}
                  style={{ width: 120 }}
                >
                  <Select.Option value={true}>
                    Enabled
                  </Select.Option>
                  <Select.Option value={false}>
                    Disabled
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </List.Item>
        </List>
      </section>
      <section>
        <List
          bordered={true}
          header={(
            <h2 className={styles.title}>
              Language
            </h2>
          )}
        >
          <List.Item>
            <Row
              style={{ width: "100%" }}
              justify="space-between"
              align="middle"
            >
              <Col>
                <span>Language</span>
                <br />
                <b>Important: The page will be reloaded after changing the language.</b>
              </Col>
              <Col>
                <Select
                  value={language}
                  onChange={onLanguageChange}
                  style={{ width: 220 }}
                >
                  <Select.Option value="en-US">
                    English (en-US)
                  </Select.Option>
                  <Select.Option value="pt-BR">
                    Portuguese (pt-BR)
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </List.Item>
        </List>
      </section>
    </div>
  )
}

export default SettingsPreferencesPage