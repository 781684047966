import React, { useEffect, useState, useRef } from "react"
import { Button, Modal, Tooltip } from "antd"
import { useParams } from "react-router-dom"

import styles from "./runOutput.module.scss"
import RestClient from "../../services/rest"
import Loading from "../loading"

const RobotRunOutput: React.FC<RobotRunOutputProps> = (props) => {
  const fetched = useRef(false)
  const { partnerId } = useParams()
  const [loading, setLoading] = useState(true)
  const [unavailable, setUnavailable] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [stdout, setStdout] = useState("")
  const [stderr, setStderr] = useState("")

  useEffect(() => {
    if (!modalVisible) return
    if (fetched.current) return
    fetchOutput(partnerId, props.robotInstanceRunId)
      .then((response) => {
        setStdout(response.stdout)
        setStderr(response.stderr)
        fetched.current = true
      })
      .catch(() => setUnavailable(true))
      .finally(() => setLoading(false))
  }, [modalVisible])

  const ModalContent = () => {
    if (loading) return <Loading />
    if (unavailable) return <h3>Output is not available 🙁</h3>
    return (
      <>
        <h3>Output</h3>
        <pre>{stdout}</pre>
        {stderr && (
          <>
            <br />
            <h3>Errors</h3>
            <pre>{stderr}</pre>
          </>
        )}
      </>
    )
  }

  return (
    <div className={styles.runOutput}>
      <Tooltip title={unavailable ? "Output is not available" : ""}>
        <Button
          disabled={unavailable}
          onClick={() => setModalVisible(true)}
        >
          {props.buttonContent || "Output"}
        </Button>
      </Tooltip>
      <Modal
        className={styles.modal}
        width={800}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose={true}
        footer={null}
      >
        <ModalContent />
      </Modal>
    </div>
  )
}

type RobotRunOutputProps = {
  buttonContent?: React.ReactNode
  robotInstanceRunId: string
}

async function fetchOutput(partnerId: string, robotInstanceRunId: string) {
  const urls = await RestClient.Realtime.Errors.Output.get(partnerId, robotInstanceRunId)
  let stdout = ""
  let stderr = ""
  if (urls.stdout) stdout = await (await fetch(urls.stdout)).text()
  if (urls.stderr) stderr = await (await fetch(urls.stderr)).text()
  if (stderr === "{}") stderr = ""
  return { stdout, stderr }
}

export default RobotRunOutput