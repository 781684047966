import React, { useEffect, useState } from "react"
import { Col, Divider, Input, Row } from "antd"

import styles from "./selectPartner.module.scss"
import RestClient from "../../services/rest"
import presentConnectionErrorMessage from "../../lib/presentConnectionErrorMessage"
import Logo from "../../components/logo/logo"
import Loading from "src/components/loading"
import i18n from "src/i18n"
import PartnerAvatar from "src/components/avatar/partner"
import { Link } from "react-router-dom"

const SelectPartnerPage: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<ResponseType["user"]>()
  const [partners, setPartners] = useState<ResponseType["partners"]>([])

  useEffect(() => {
    getPartners()
      .then(response => {
        setPartners(response.partners)
        setUser(response.user)
      })
      .catch(error => {
        presentConnectionErrorMessage(error)
      })
      .finally(() => setLoading(false))
  }, [])

  if (loading) return (
    <Loading />
  )

  return (
    <div className={styles.selectPartner}>
      <Logo />
      <h2>
        Welcome to Partner's Portal, {user.name.split(" ")[0]} 👋
      </h2>
      <p>
        Select a partner account to continue
      </p>
      <Divider />
      <Row gutter={16}>
        {partners.map(partner => (
          <Col
            key={partner._id as string}
            xs={24} sm={12}
          >
            <Link
              className={styles.partner}
              to={`/${partner._id}/dashboard`}
            >
              <PartnerAvatar name={partner.displayName} />
              <div className={styles.info}>
                <b>{partner.displayName}</b>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
      <Divider />
      <small>
        {i18n["app.copyright"]}
      </small>
    </div>
  )
}

async function getPartners() {
  return await RestClient.Authorized.get()
}

type ResponseType = Awaited<ReturnType<typeof getPartners>>

export default SelectPartnerPage