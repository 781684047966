import React, { useEffect, useState } from "react"
import { Tabs } from "antd"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"

import styles from "./index.module.scss"
import Layout from "../../components/layout"

const SettingsPage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState("")

  useEffect(() => {
    const tab = location.pathname.split("/")[3]
    if (tab) {
      setActiveTab(tab)
    } else {
      navigate("users")
    }
  }, [location])

  return (
    <Layout currentMenu="settings">
      <h1 className={styles.title}>Settings</h1>
      <Tabs
        activeKey={activeTab}
        items={[
          {
            key: "users",
            label: <Link to="users">Users</Link>,
          },
          {
            key: "preferences",
            label: <Link to="preferences">Preferences</Link>,
          },
        ]}
      />
      <Outlet />
    </Layout>
  )
}

export default SettingsPage