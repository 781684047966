import moment from "moment"
import momentDurationFormatSetup from "moment-duration-format"

const defaultFormat = "H[h]m[m]ss[s]"

function formatDuration(duration: moment.Duration | number, format = defaultFormat): string {
  momentDurationFormatSetup(moment)
  try {
    if (typeof duration === "number") duration = moment.duration(duration, "seconds")
    // @ts-expect-error
    const durationString = duration.format(format, {
      trim: true
    })
    return durationString
  } catch (error) {
    return "Invalid duration"
  }
}

export default formatDuration