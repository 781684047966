import React, { useEffect, useState } from "react"
import moment from "moment"
import { Models } from "@roberty/models"
import { Col, Empty, Progress, Row } from "antd"
import { useParams } from "react-router-dom"

import styles from "./running.module.scss"
import Loading from "../loading"
import formatDuration from "../../lib/formatDuration"
import StatisticsCard from "../statisticsCard"
import WorkspaceAvatar from "../avatar/workspace"
import RestClient from "../../services/rest"
import presentConnectionErrorMessage from "../../lib/presentConnectionErrorMessage"
import i18n from "../../i18n"

const RealtimeRunning: React.FC = () => {
  const { partnerId } = useParams()
  const [loading, setLoading] = useState(true)
  const [robots, setRobots] = useState<RunningRobotsResponse>([])

  useEffect(() => {
    fetchRunning(partnerId)
      .then(setRobots)
      .finally(() => setLoading(false))
    const loop = setInterval(() => {
      fetchRunning(partnerId)
        .then(setRobots)
    }, 30000)
    return () => {
      clearInterval(loop)
    }
  }, [])

  return (
    <div className={styles.running}>
      {loading && (
        <Loading />
      )}
      <Row gutter={16}>
        {robots.map((data) => (
          <Col key={data.robot._id as string} xl={8} lg={12} xs={24}>
            <RealtimeRobotStatus
              averageDuration={data.averageDuration}
              startedAt={data.startedAt}
              robot={data.robot}
              workspace={data.workspace}
            />
          </Col>
        ))}
      </Row>
      {robots.length === 0 && !loading && (
        <Row justify="center">
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            description={i18n["realtime.running.empty"]}
          />
        </Row>
      )}
    </div>
  )
}

const RealtimeRobotStatus: React.FC<RealtimeRobotStatusProps> = (props) => {
  const startedAt = moment(props.startedAt)
  const initialDuration = moment.duration(moment().diff(startedAt)).asSeconds()

  const [duration, setDuration] = useState(initialDuration)

  const formattedDuration = formatDuration(duration)
  const formattedAverageDuration = formatDuration(props.averageDuration)
  const takingLonger = duration > props.averageDuration
  const variation = takingLonger 
  ? i18n["realtime.running.takingLonger"] 
  : i18n["realtime.running.inProgress"] 
  const color = takingLonger ? "orange" : "var(--blue)"
  const progress = Math.min(100, (duration / props.averageDuration) * 100)

  useEffect(() => {
    setTimeout(() => {
      setDuration(duration + 1)
    }, 1000)
  }, [duration])

  return (
    <StatisticsCard
      data={formattedDuration}
      title={props.robot.name}
      variation={variation}
      variationColor={color}
      showVariationIcon={false}
    >
      <div className={styles.workspace}>
        <WorkspaceAvatar
          workspaceId={props.workspace._id as string}
          name={props.workspace.displayName}
          size="small"
        />
        <small>{props.workspace.displayName}</small>
      </div>
      <div className={styles.progress}>
        <Progress
          strokeColor={color}
          trailColor="var(--border)"
          showInfo={false}
          percent={progress}
        />
        {/* <Button
          type="primary"
          size="small"
          danger={true}
        >
          {i18n["realtime.running.stop"]}
        </Button> */}
      </div>
      <small>{i18n["realtime.running.averageDuration"]}: {formattedAverageDuration}</small>
    </StatisticsCard>
  )
}

type RealtimeRobotStatusProps = {
  robot: Pick<Models.Robot, "_id" | "name">
  workspace: Pick<Models.Customer, "_id" | "displayName">
  startedAt: Date
  averageDuration: number
}

async function fetchRunning(partnerId: string) {
  try {
    return await RestClient.Realtime.Running.get(partnerId)
  } catch (error) {
    presentConnectionErrorMessage()
    return []
  }
}

type RunningRobotsResponse = Awaited<ReturnType<typeof fetchRunning>>

export default RealtimeRunning