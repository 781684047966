import React from "react"
import { Col, Row } from "antd"

import styles from "./index.module.scss"

import Layout from "../../components/layout"
import DashboardWorkedHours from "src/components/dashboard/workedHours"
import DashboardSavedHours from "src/components/dashboard/savedHours"
import DashboardSavedCosts from "src/components/dashboard/savedCosts"
import DashboardActiveRobots from "src/components/dashboard/activeRobots"
import DashboardActiveDevices from "src/components/dashboard/activeDevices"
import DashboardErrors from "src/components/dashboard/errors"

const DashboardPage: React.FC = () => {
  return (
    <Layout currentMenu="dashboard">
      <h1 className={styles.title}>Dashboard</h1>
      <section>
        <h2 className={styles.title}>This month</h2>
        <Row gutter={16}>
          <Col xl={8} sm={12} xs={24}>
            <DashboardWorkedHours />
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <DashboardSavedHours />
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <DashboardSavedCosts />
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <DashboardErrors />
          </Col>
        </Row>
      </section>
      <section>
        <h2 className={styles.title}>General stats</h2>
        <Row gutter={16}>
          <Col xl={8} sm={12} xs={24}>
            <DashboardActiveRobots />
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <DashboardActiveDevices />
          </Col>
        </Row>
      </section>
    </Layout>
  )
}

export default DashboardPage