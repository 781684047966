import React, { useEffect, useState } from "react"
import { Col, Row, Select } from "antd"
import { Models } from "@roberty/models"
import { useParams } from "react-router-dom"

import styles from "./selectUsers.module.scss"

import isEmailValid from "src/lib/isEmailValid"
import UserAvatar from "../avatar/user"
import RestClient from "src/services/rest"

const SelectUsers: React.FC<SelectUsersProps> = (props) => {
  const { partnerId } = useParams()

  const [dataSource, setDataSource] = useState<UserResponse[]>([])
  const [value, setValue] = useState<string[]>([])
  const [searchText, setSearchText] = useState("")

  const filteredUsers = dataSource.filter(user => !value.includes(user.email))
  const newUser = searchText && isEmailValid(searchText) ? getFakeUser(searchText) : undefined
  const availableUsers = (newUser ? [newUser, ...filteredUsers] : filteredUsers)
    .filter(user => !props.emailsToHide.includes(user.email))

  useEffect(() => {
    fetchUsers(partnerId)
      .then(setDataSource)
  }, [])

  useEffect(() => {
    if (props.onChange)
      props.onChange(value)
  }, [value])

  return (
    <Select
      className={styles.selectUsers}
      size="large"
      mode={props.multiple ? "multiple" : undefined}
      placeholder={props.placeholder}
      onChange={setValue}
      onSearch={setSearchText}
    >
      {availableUsers.map(user => (
        <Select.Option
          className={styles.user}
          key={user._id.toString()}
          value={user.email}
        >
          <Row
            gutter={16}
            align="middle"
          >
            <Col>
              <UserAvatar
                name={user.name}
                size="small"
              />
            </Col>
            <Col>
              <b>{user.name}</b>
            </Col>
          </Row>
        </Select.Option>
      ))}
    </Select>
  )
}

SelectUsers.defaultProps = {
  emailsToHide: []
}

type SelectUsersProps = {
  placeholder?: string
  multiple?: boolean
  emailsToHide?: string[]
  onChange?: (emails: string[]) => void
}

const getFakeUser = (email: string): UserResponse => {
  return {
    _id: Date.now().toString(),
    email,
    name: email
  }
}

async function fetchUsers(partnerId: string) {
  const authorizations = await RestClient.Authorizations.get(partnerId)
  return authorizations.map(auth => auth.user).filter(user => !!user)
}

type UserResponse = Pick<Models.User, "_id" | "email" | "name">

export default SelectUsers