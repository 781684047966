import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import moment from "moment"
import { Button, Col, FloatButton, Row, Tooltip, message, Table } from "antd"

import styles from "./errors.module.scss"
import RestClient from "../../services/rest"
import presentConnectionErrorMessage from "../../lib/presentConnectionErrorMessage"
import Loading from "../loading"
import formatDuration from "../../lib/formatDuration"
import WorkspaceAvatar from "../avatar/workspace"
import i18n from "../../i18n"
import RobotRunOutput from "../robot/runOutput"
import RobotRunScreenshot from "../robot/runScreenshot"

const RealtimeErrors: React.FC = () => {
  const { partnerId } = useParams()
  const [dataSource, setDataSource] = useState<RobotErrorsResponse>([])
  const [loading, setLoading] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])

  useEffect(() => {
    load(true)
    const loop = setInterval(() => {
      load()
    }, 120000)
    return () => {
      clearInterval(loop)
    }
  }, [])

  const load = (showLoader = false) => {
    if (showLoader) setLoading(true)
    fetchErrors(partnerId)
      .then(setDataSource)
      .finally(() => setLoading(false))
  }

  const markAsSolved = (robotInstanceRunId?: string) => {
    const selectedRuns = robotInstanceRunId ? [robotInstanceRunId] : selectedRowKeys
    const confimed = confirm(`Are you sure you want to mark ${selectedRuns.length} as solved?`)
    if (!confimed) return
    fetchMarkAsSolved(partnerId, selectedRuns)
      .then(() => {
        message.success(`${selectedRuns.length} marked as solved`)
        setSelectedRowKeys([])
        load(true)
      })
  }

  if (loading) return (
    <Loading />
  )

  return (
    <div className={styles.errors}>
      {selectedRowKeys.length > 0 && (
        <FloatButton
          className={styles.markAsSolved}
          type="primary"
          shape="square"
          icon={i18n["realtime.errors.markAsSolved"](selectedRowKeys.length)}
          onClick={() => markAsSolved()}
        />
      )}
      <Table
        dataSource={dataSource}
        rowKey={(record: RobotErrorsResponse[0]) => record.robotInstanceRun._id as string}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          pageSize: 20
        }}
        scroll={{ x: "calc(100vw - 30%)" }}
        columns={[
          {
            title: "Robot",
            render: (record: RobotErrorsResponse[0]) => (
              <Row
                align="middle"
                gutter={16}
              >
                <Col>
                  <WorkspaceAvatar
                    workspaceId={record.workspace._id as string}
                    name={record.workspace.displayName}
                  />
                </Col>
                <Col>
                  <b>{record.robot.name}</b>
                  <br />
                  <small>{record.workspace.displayName}</small>
                </Col>
              </Row>
            )
          },
          {
            title: "Error info",
            render: (record: RobotErrorsResponse[0]) => (
              <pre>
                {i18n["realtime.errors.type"]}: {record.robotInstanceRun.kind}
                <br />
                {i18n["realtime.errors.duration"]}: {formatDuration(record.robotInstanceRun.duration)}
                <br />
                <Tooltip
                  placement="bottom"
                  title={`Failed at ` + new Date(record.robotInstanceRun.endDate).toLocaleString()}
                >
                  {i18n["realtime.errors.failedAt"]} {moment(record.robotInstanceRun.endDate).fromNow()}
                </Tooltip>
                <br />
                <Tooltip
                  placement="bottom"
                  title={`Last run was ` + new Date(record.robotInstanceRun.endDate).toLocaleString()}
                >
                  {i18n["realtime.errors.lastRunAt"]} {moment(record.robotInstanceRun.endDate).fromNow()}
                </Tooltip>
              </pre>
            )
          },
          {
            title: "Actions",
            render: (record: RobotErrorsResponse[0]) => (
              <Row gutter={16}>
                <Col>
                  <RobotRunOutput
                    robotInstanceRunId={record.robotInstanceRun._id as string}
                  />
                </Col>
                <Col>
                  <RobotRunScreenshot
                    robotInstanceRunId={record.robotInstanceRun._id as string}
                  />
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => markAsSolved(record.robotInstanceRun._id as string)}
                  >
                    {i18n["realtime.errors.markAsSolved"]()}
                  </Button>
                </Col>
              </Row>
            )
          },
        ]}
        rowSelection={{
          onChange: (selectedRowKeys: string[]) => setSelectedRowKeys(selectedRowKeys)
        }}
      />
    </div>
  )
}

async function fetchErrors(partnerId: string) {
  try {
    return await RestClient.Realtime.Errors.get(partnerId)
  } catch (error) {
    presentConnectionErrorMessage()
    return []
  }
}

async function fetchMarkAsSolved(partnerId: string, robotInstanceRunIds: string[]) {
  try {
    await RestClient.Realtime.Errors.MarkAsSolved.post(partnerId, robotInstanceRunIds)
  } catch (error) {
    presentConnectionErrorMessage()
    throw error
  }
}

type RobotErrorsResponse = Awaited<ReturnType<typeof fetchErrors>>

export default RealtimeErrors