import { Address } from '@roberty/models/src/types/Address'
import { Alert, Breadcrumb, Button, Form, Input, message } from 'antd'
import { MaskedInput } from 'antd-mask-input'
import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AddressForm from 'src/components/addressForm'
import Layout from 'src/components/layout'
import Loading from 'src/components/loading'
import SelectUsers from 'src/components/selectUsers'
import presentConnectionErrorMessage from 'src/lib/presentConnectionErrorMessage'
import AuthenticationService from 'src/services/authentication'
import RestClient from 'src/services/rest'

import styles from './create.module.scss'

const WorkspaceCreate: React.FC = () => {
  const user = AuthenticationService.getUser()
  const { partnerId } = useParams()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [displayName, setDisplayName] = useState("")
  const [email, setEmail] = useState("")
  const [adminEmails, setAdminEmails] = useState<string[]>([])
  const [developerEmails, setDeveloperEmails] = useState<string[]>([])
  const [otherUserEmails, setOtherUserEmails] = useState<string[]>([])
  const [legalName, setLegalName] = useState("")
  const [federalDocument, setFederalDocument] = useState("")
  const [address, setAddress] = useState<Address>()

  const emailsAlreadyAdded = [
    user.email,
    ...adminEmails,
    ...developerEmails,
    ...otherUserEmails
  ]

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    try {
      await RestClient.Workspace.post(partnerId, {
        displayName,
        email,
        adminEmails,
        developerEmails,
        otherUserEmails,
        legalName,
        federalDocument,
        address
      })
      message.success("Workspace created successfully 🥳")
      navigate(`/${partnerId}/workspaces`)
    } catch (error) {
      presentConnectionErrorMessage(error)
      setLoading(false)
    }
  }

  return (
    <Layout currentMenu="workspaces">
      {loading && (<Loading />)}
      <div
        className={styles.create}
        hidden={loading}
      >
        <section>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/${partnerId}/workspaces`}>
                {`< Workspaces`}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <h1 className={styles.title}>
                Create workspace
              </h1>
            </Breadcrumb.Item>
          </Breadcrumb>
        </section>
        <Form
          form={form}
          onSubmitCapture={handleSubmit}
        >
          <section>
            <h2 className={styles.title}>
              Workspace info
            </h2>
            <label htmlFor="displayName">Workspace name:</label>
            <Input
              autoFocus={true}
              id="displayName"
              name="displayName"
              placeholder="Fill workspace name here"
              required={true}
              value={displayName}
              onChange={e => setDisplayName(e.target.value)}
            />
            <label htmlFor="displayName">Contact email (used for billing and legal notifications):</label>
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="billing_sector@company.com"
              required={true}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </section>
          <section>
            <h2 className={styles.title}>
              Workspace users
            </h2>
            <Alert
              showIcon={true}
              className={styles.alert}
              closable={true}
              message=" When you create a workspace, you automatically become an admin of that workspace."
            />
            <label htmlFor="admins">Admins:</label>
            <SelectUsers
              placeholder="Click to add users"
              multiple={true}
              emailsToHide={emailsAlreadyAdded}
              onChange={setAdminEmails}
            />
            <label htmlFor="developers">Developers:</label>
            <SelectUsers
              placeholder="Click to add users"
              multiple={true}
              emailsToHide={emailsAlreadyAdded}
              onChange={setDeveloperEmails}
            />
            <label htmlFor="otherUsers">Other users:</label>
            <SelectUsers
              placeholder="Click to add users"
              multiple={true}
              emailsToHide={emailsAlreadyAdded}
              onChange={setOtherUserEmails}
            />
          </section>
          <section>
            <h2 className={styles.title}>
              Informações legais da empresa (Brasil)
            </h2>
            <h2 className={styles.title}>
              <small>Dados cadastrais</small>
            </h2>
            <label htmlFor="displayName">Razão social:</label>
            <Input
              id="legalName"
              name="legalName"
              placeholder="Nome da empresa LTDA"
              required={true}
              value={legalName}
              onChange={e => setLegalName(e.target.value)}
            />
            <label htmlFor="displayName">CNPJ:</label>
            <MaskedInput
              mask="00.000.000/0000-00"
              placeholder="01.234.567/8901-23"
              onBlur={e => setFederalDocument(e.target.value)}
            />
            <h2 className={styles.title}>
              <small>Endereço</small>
            </h2>
            <AddressForm
              onChange={setAddress}
            />
          </section>
          <section>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block={true}
            >
              Create workspace
            </Button>
          </section>
        </Form>
      </div>
    </Layout>
  )
}

export default WorkspaceCreate