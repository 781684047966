import React, { useRef, useState, useEffect } from "react"
import { Button, Image, Modal, Tooltip } from "antd"
import { useParams } from "react-router-dom"

import Loading from "../loading"
import RestClient from "../../services/rest"

const RobotRunScreenshot: React.FC<RobotRunScreenshotProps> = (props) => {
  const fetched = useRef(false)
  const { partnerId } = useParams()
  const [loading, setLoading] = useState(true)
  const [unavailable, setUnavailable] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [url, setUrl] = useState("")

  useEffect(() => {
    if (!modalVisible) return
    if (fetched.current) return
    fetchScreenshotUrl(partnerId, props.robotInstanceRunId)
      .then((response) => {
        setUrl(response)
        fetched.current = true
      })
      .catch(() => setUnavailable(true))
      .finally(() => setLoading(false))
  }, [modalVisible])

  const ModalContent = () => {
    if (loading) return <Loading />
    if (unavailable) return (
      <h3 style={{ color: "black" }}>
        Screenshot is not available 🙁
      </h3>
    )
    return (
      <Image
        src={url}
        width={"100%"}
        preview={{
          visible: true,
          height: window.innerHeight,
          onVisibleChange: (visible) => {
            if (!visible) setModalVisible(false)
          }
        }}
      />
    )
  }

  return (
    <div>
      <Tooltip title={unavailable ? "Screenshot is not available" : ""}>
        <Button
          disabled={unavailable}
          onClick={() => setModalVisible(true)}
        >
          {props.buttonContent || "Screenshot"}
        </Button>
      </Tooltip>
      <Modal
        width={800}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose={true}
        footer={null}
      >
        <ModalContent />
      </Modal>
    </div>
  )
}

type RobotRunScreenshotProps = {
  buttonContent?: React.ReactNode,
  robotInstanceRunId: string
}

async function fetchScreenshotUrl(partnerId: string, robotInstanceRunId: string) {
  const url = await RestClient.Realtime.Errors.Screenshot.get(partnerId, robotInstanceRunId)
  const response = await fetch(url)
  if (!response.ok) throw new Error("Failed to fetch screenshot")
  return url
}

export default RobotRunScreenshot