import { Enums } from '@roberty/models'
import { Col, Progress, Row } from 'antd'
import React from 'react'
import formatDuration from 'src/lib/formatDuration'

import getFeatureName from './getFeatureName'
import styles from './usage.module.scss'

const Usage: React.FC<UsageProps> = (props) => {
  const name = getFeatureName(props.feature)
  const used = Number(props.used)
  const available = Number(props.available)
  const unlimited = available === Infinity
  const percent = unlimited
    ? 100
    : (props.used / props.available) * 100
  const color = unlimited
    ? "transparent"
    : percent >= 90 ? "var(--pink)" : "var(--green)"

  return (
    <div className={styles.usage}>
      <Row
        className={styles.info}
        justify="space-between"
      >
        <Col>
          {name}
        </Col>
        <Col>
          {props.feature === "RUN_SECONDS" ? (
            <>
              <span>Used {formatDuration(used)}</span>
              <span> of </span>
              <span>{unlimited ? "♾️" : formatDuration(available)}</span>
            </>
          ) : (
            <>
              <span>Used {used}</span>
              <span> of </span>
              <span>{unlimited ? "♾️" : available}</span>
            </>
          )}
        </Col>
      </Row>
      <Progress
        percent={percent}
        strokeColor={color}
        showInfo={false}
        trailColor="var(--border)"
        status={unlimited ? "active" : "normal"}
      />
    </div>
  )
}

type UsageProps = {
  feature: Enums.SubscriptionConsumableFeatures
  available: number
  used: number
}

export default Usage