import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import RestClient from "src/services/rest"
import StatisticsCard, { VariationType } from "../statisticsCard"

const DashboardErrors: React.FC = () => {
  const { partnerId } = useParams()
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [variation, setVariation] = useState(0)
  const [variationType, setVariationType] = useState<VariationType>("none")

  useEffect(() => {
    fetchErrors(partnerId)
      .then((workedHours) => {
        setTotal(workedHours.total)
        setVariation(workedHours.variation)
        setVariationType(workedHours.variationType as VariationType)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <StatisticsCard
      title="Errors"
      data={total}
      variation={variation}
      variationType={variationType}
      variationColor={variationType === "up" ? "var(--pink)" : "var(--green)"}
      loading={loading}
    />
  )
}

async function fetchErrors(partnerId: string) {
  return await RestClient.Dashboard.Errors.get(partnerId)
}

export default DashboardErrors