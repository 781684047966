import React, { useEffect, useState } from "react"
import { Avatar, AvatarProps } from "antd"
import { useParams } from "react-router-dom"

import RestClient from "../../services/rest"

const WorkspaceAvatar: React.FC<WorkspaceAvatarProps> = (props) => {
  const { partnerId } = useParams()
  const [url, setUrl] = useState(props.src)
  const { workspaceId, name, src, ...rest } = props

  useEffect(() => {
    fetchPictureUrl(partnerId, workspaceId)
      .then(setUrl)
      .catch(() => setUrl(`https://ui-avatars.com/api/?name=${props.name}&background=random`))
  }, [workspaceId])

  return (
    <Avatar
      src={url}
      {...rest}
    />
  )
}

type WorkspaceAvatarProps = AvatarProps & {
  workspaceId?: string
  src?: string
  name: string
}

async function fetchPictureUrl(partnerId: string, workspaceId: string) {
  if (!partnerId) return
  if (!workspaceId) return
  const key = `workspace-${workspaceId}-logo`
  const stored = localStorage.getItem(key)
  if (stored) {
    const { url, expires } = JSON.parse(stored) as StoredImage
    if (expires > Date.now()) return url
  }
  const url = await RestClient.Workspace.getLogoURL(partnerId, workspaceId)
  localStorage.setItem(key, JSON.stringify({
    url,
    expires: Date.now() + 15 * 60 * 1000 // expires in 15 minutes
  }))
  return url
}

type StoredImage = {
  url: string
  expires: number
}

export default WorkspaceAvatar