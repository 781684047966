export const getFormattedFederalDocument = (value: string = ``) => {
	const { groups } = value
		.replace(/[^0-9]/gi, "")
		.match(/(?<p1>[0-9]{0,2})(?<p2>[0-9]{0,3})(?<p3>[0-9]{0,3})(?<p4>[0-9]{0,4})(?<p5>[0-9]{0,2}).*/i)

	let output = `${groups["p1"]}`

	if (groups["p2"].length > 0) output += `.${groups["p2"]}`
	if (groups["p3"].length > 0) output += `.${groups["p3"]}`
	if (groups["p4"].length > 0) output += `/${groups["p4"]}`
	if (groups["p5"].length > 0) output += `-${groups["p5"]}`

	return output
}

export const getFormattedZipCode = (value: string = ``) => {
	const { groups } = value
		.replace(/[^0-9]/gi, "")
		.match(/(?<p1>[0-9]{0,5})(?<p2>[0-9]{0,3}).*/i)

	let output = `${groups["p1"]}`

	if (groups["p2"].length > 0) output += `-${groups["p2"]}`

	return output
}