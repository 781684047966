import React, { PropsWithChildren, useContext } from "react"
import { Link, useParams } from "react-router-dom"
import clsx from "clsx"

import styles from "./desktop.module.scss"
import { Scroll } from "../scroll"
import Logo from "../logo/logo"
import { LayoutContext } from "../layout/layout.context"

const DesktopMainMenu: React.FC<PropsWithChildren> = ({ children }) => {
  const { partnerId } = useParams()
  const layoutContext = useContext(LayoutContext)
  return (
    <div className={clsx({
      [styles.desktop]: true,
      [styles.compact]: layoutContext.compactMenu,
    })}>
      <ToggleCompactMenu />
      <Scroll disableOnMacOS={false}>
        <div className={styles.scrollContainer}>
          <Link to={`/${partnerId}`}>
            <Logo
              className={styles.logo}
              compact={layoutContext.compactMenu}
            />
          </Link>
          {children}
        </div>
      </Scroll>
    </div>
  )
}

const ToggleCompactMenu: React.FC = () => {
  const layoutContext = useContext(LayoutContext)
  const toggleCompactMenu = () => {
    layoutContext.setCompactMenu(!layoutContext.compactMenu)
  }
  return (
    <button
      className={styles.compactToggle}
      onClick={toggleCompactMenu}
    >
      {layoutContext.compactMenu ? ">" : "<"}
    </button>
  )
}

export default DesktopMainMenu