import React from "react"
import StatisticsCard from "../statisticsCard"


const DashboardSavedCosts: React.FC = () => {
  return (
    <StatisticsCard
      title="Savings statistics"
      data="Coming soon 💰"
      variationType="none"
      showVariationIcon={false}
      disabled={true}
    />
  )
}

export default DashboardSavedCosts