import * as cnpj from '@fnando/cnpj'
import * as cpf from '@fnando/cpf'
import { useHookstate } from '@hookstate/core'
import { Enums, Schemas } from '@roberty/models'
import { message, Modal, notification } from 'antd'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import RestClient from 'src/services/rest'
import { generateErrorMessage } from 'zod-error'

import { fetchZipCode } from '../../../lib/viaCep'
import { LocalState } from './state'

const WorkspaceSaveValidation = Schemas.CustomerSchema._def.schema
	.pick({
		active: true,
		type: true,
		displayName: true,
		email: true,
		legalName: true,
		federalDocument: true,
		partner_: true,
		address: true,
	})
	.extend({
		address: Schemas.CustomerSchema._def.schema.shape.address.unwrap()
			.omit({ country: true })
			.optional(),
	})
	.refine(({ type, federalDocument }) => {
		switch (type) {
			case Enums.CustomerType["BUSINESS"]: {
				return cnpj.isValid(federalDocument)
			}
			case Enums.CustomerType["PERSON"]: {
				return cpf.isValid(federalDocument)
			}
		}
	}, "invalid \"federalDocument\" due the \"type\"")

export const useControllers = () => {
	const { partnerId, workspaceId } = useParams()
	const state = useHookstate(LocalState)

	const handleZipCodeLookup = async () => {
		if (state.workspace?.address?.zipCode?.get().length !== 9) return

		const destroy = message.loading({
			content: "Fetching zip code...",
			duration: 0
		})
		try {
			state.loadingZipCode.set(true)
			const zipCodeFetched = await fetchZipCode(state.workspace?.address?.zipCode?.get())

			if (zipCodeFetched.state) state.workspace.address.state.set(zipCodeFetched.state)
			if (zipCodeFetched.city) state.workspace.address.city.set(zipCodeFetched.city)
			if (zipCodeFetched.address) state.workspace.address.address.set(zipCodeFetched.address)
			if (zipCodeFetched.district) state.workspace.address.district.set(zipCodeFetched.district)
		} catch (e) {
			notification.error({
				message: "Error when fetching zip code...",
				description: "Try again!"
			})
		} finally {
			state.loadingZipCode.set(false)
			destroy()
		}
	}
	useEffect(() => { handleZipCodeLookup() }, [state.workspace?.address?.zipCode])

	const canSave = () => {
		const result = WorkspaceSaveValidation.safeParse(state.workspace.get())
		state.errors.set({})
		if ((result as any)?.error?.issues) state.errors.set(Object.fromEntries(generateErrorMessage(
			(result as any).error.issues,
			{
				delimiter: { component: ` - ` },
				code: { enabled: false },
				path: { label: ``, enabled: true, type: `objectNotation` },
				message: { label: ``, enabled: true }
			}
		).split(` | `).map(b => b.split(` - `))))
		return result.success
	}

	const handleSave = (confirmed?: boolean) => async () => {
		if (!confirmed) {
			Modal.confirm({
				content: "Save changes?",
				cancelText: "No",
				cancelButtonProps: { type: "dashed" },
				okText: "Yes, save",
				onOk: handleSave(true)
			})
			return
		}
		const destroy = message.loading({
			content: "Saving workspace...",
			duration: 0
		})
		try {
			const workspace = WorkspaceSaveValidation.parse(state.workspace.get())
			await RestClient.Workspace.put(partnerId, workspaceId, workspace as any)
			notification.success({
				message: "Workspace changed with success!"
			})
			// navigate("..")
		} catch (e) {
			console.error(e)
			notification.error({
				message: "Error during workspace saving...",
				description: "Try again!"
			})
		} finally {
			destroy()
		}
	}

	return {
		canSave,
		handleSave,
	}
}