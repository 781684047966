import { Enums } from '@roberty/models'
import { Button, Col, FloatButton, message, Row, Select, Table } from 'antd'
import React from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import UserAvatar from 'src/components/avatar/user'
import StatisticsCard from 'src/components/statisticsCard'
import presentConnectionErrorMessage from 'src/lib/presentConnectionErrorMessage'
import RestClient from 'src/services/rest'

import styles from './index.module.scss'

const SettingsUsersPage: React.FC = () => {
  const { partnerId } = useParams()
  const location = useLocation()
  const [loading, setLoading] = React.useState(true)
  const [authorizations, setAuthorizations] = React.useState<ResponseType[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([])

  const load = () =>
    fetchAuthorizations(partnerId)
      .then(setAuthorizations)
      .catch(presentConnectionErrorMessage)
      .finally(() => setLoading(false))

  React.useEffect(() => { load() }, [location])

  const onDelete = (authorizationId?: string) => {
    const ids: string[] = []
    if (authorizationId) ids.push(authorizationId)
    else ids.push(...selectedRowKeys)
    if (!confirm(`Are you sure you want to delete ${ids.length} users?`)) return
    setLoading(true)
    fetchDeleteUsers(partnerId, ids)
      .then(() => {
        setSelectedRowKeys([])
        load()
      })
      .catch((error) => {
        presentConnectionErrorMessage(error)
        setLoading(false)
      })
  }

  const onRoleChange = (autorhizationId: string, role: Enums.PartnerUserAuthorizationRole) => {
    setLoading(true)
    if (!confirm(`Are you sure you want to change the role of this user?`)) return
    fetchRoleChange(partnerId, autorhizationId, role)
      .then(() => {
        message.success("Role changed 😁")
      })
      .catch((error) => {
        presentConnectionErrorMessage(error)
        load()
      })
      .finally(load)
  }

  return (
    <div className={styles.users}>
      {selectedRowKeys.length > 0 && (
        <FloatButton
          className={styles.deleteMany}
          type="primary"
          shape="square"
          icon={`Delete ${selectedRowKeys.length} users`}
          onClick={() => onDelete()}
        />
      )}
      <section>
        <Row gutter={16}>
          <Col xs={24} lg={12} xl={8}>
            <StatisticsCard
              title="Total users"
              data={authorizations.length}
              loading={loading}
            />
          </Col>
        </Row>
      </section>
      <section>
        <Link to="invite">
          <Button
            type="primary"
            size="large"
          >
            Invite user
          </Button>
        </Link>
        <Outlet />
      </section>
      <Table
        rowKey={record => record._id as string}
        dataSource={authorizations}
        loading={loading}
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys: string[]) =>
            setSelectedRowKeys(selectedRowKeys)
        }}
        columns={[
          {
            title: "Select all",
            render: (record: ResponseType) => (
              <Row justify="space-between">
                <Col>
                  <Row
                    align="middle"
                    gutter={16}
                  >
                    <Col>
                      <UserAvatar
                        name={record.user.name}
                      />
                    </Col>
                    <Col>
                      <b>{record.user.name}</b>
                      <br />
                      <small>{record.user.email}</small>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Select
                    style={{ width: 160, marginRight: "1rem" }}
                    value={record.role}
                    onChange={(role) => onRoleChange(record._id as string, role)}
                  >
                    <Select.Option value="ADMIN">
                      Admin
                    </Select.Option>
                    <Select.Option value="DEVELOPER">
                      Developer
                    </Select.Option>
                    <Select.Option value="BILLING">
                      Billing
                    </Select.Option>
                  </Select>
                  <Button
                    danger={true}
                    onClick={() => onDelete(record._id as string)}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            )
          }
        ]}
      />
    </div>
  )
}

async function fetchAuthorizations(partnerId: string) {
  return await RestClient.Authorizations.get(partnerId)
}

async function fetchDeleteUsers(partnerId: string, authorizationIds: string[]) {
  return await RestClient.Authorizations.delete(partnerId, authorizationIds)
}

async function fetchRoleChange(partnerId: string, authorizationId: string, role: Enums.PartnerUserAuthorizationRole) {
  return await RestClient.Authorization.put(partnerId, authorizationId, role)
}

type ResponseType = Awaited<ReturnType<typeof fetchAuthorizations>>[0]

export default SettingsUsersPage