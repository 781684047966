import React, { PropsWithChildren, useCallback, useState } from "react"
import { Button, Modal } from "antd"
import { Link, useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"

import styles from "./mobile.module.scss"
import menuIcon from "./icons/menu.svg"
import Logo from "../logo/logo"
import i18n from "../../i18n"

const MobileMainMenu: React.FC<PropsWithChildren> = ({ children }) => {
  const { partnerId } = useParams()
  const [open, setOpen] = useState(false)
  const toggle = useCallback(() => { setOpen(!open) }, [open])
  return (
    <div className={styles.mobile}>
      <Link to={`/${partnerId}`}>
        <Logo className={styles.logo} />
      </Link>
      <Button
        type="link"
        onClick={toggle}
      >
        <ReactSVG
          className={styles.button}
          src={menuIcon}
          wrapper="span"
        />
      </Button>
      <Modal
        open={open}
        closable={true}
        maskClosable={true}
        closeIcon={<></>}
        cancelText={i18n["common.close"]}
        onCancel={toggle}
        okButtonProps={{ style: { display: "none" } }}
        style={{
          top: "3rem",
          maxWidth: "80%"
        }}
      >
        {children}
      </Modal>
    </div>
  )
}

export default MobileMainMenu