import { Models } from "@roberty/models"
import { Col, Row, Table } from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import UserAvatar from "src/components/avatar/user"
import StatisticsCard from "src/components/statisticsCard"
import presentConnectionErrorMessage from "src/lib/presentConnectionErrorMessage"
import RestClient from "src/services/rest"

const WorkspaceUsers: React.FC = () => {
  const { partnerId, workspaceId } = useParams()
  const [loading, setLoading] = useState(true)
  const [dataSource, setDataSource] = useState<Models.User[]>([])
  useEffect(() => {
    fetchUsers(partnerId, workspaceId)
      .then(setDataSource)
      .catch(presentConnectionErrorMessage)
      .finally(() => setLoading(false))
  }, [])
  return (
    <>
      <section>
        <Row>
          <Col xs={24} lg={8} xl={6}>
            <StatisticsCard
              title="Total users"
              data={dataSource.length}
              loading={loading}
            />
          </Col>
        </Row>
      </section>
      <section>
        <Table
          rowKey={record => record._id as string}
          dataSource={dataSource}
          loading={loading}
          columns={[
            {
              title: "Name",
              render: (record: Models.User) => (
                <Row
                  gutter={16}
                  align="middle"
                >
                  <Col>
                    <UserAvatar
                      name={record.name || record.email}
                    />
                  </Col>
                  <Col>
                    {record.name || `Invited user (${record.email})`}
                  </Col>
                </Row>
              )
            }
          ]}
          pagination={{ defaultPageSize: 20 }}
        />
      </section>
    </>
  )
}

async function fetchUsers(partnerId: string, workspaceId: string) {
  return await RestClient.Workspace.Users.get(partnerId, workspaceId)
}

export default WorkspaceUsers