import { useEffect, useState } from "react"
import { createRoot } from "react-dom/client"
import { ConfigProvider, theme } from "antd"

import "./styles/global.scss"
import Routes from "./pages/routes"
import DarkModeService from "./services/darkMode"
import Suspense from "./components/suspense"
import AuthenticationService from "./services/authentication"
import LocaleService from "./services/locale"

const App: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [algorithm, setAlgorithm] = useState(DarkModeService.get(true))

  const authenticate = async () => {
    const authenticated = await AuthenticationService.check()
    if (authenticated) setLoading(false)
  }

  useEffect(() => {
    DarkModeService.start()
    DarkModeService.onChange(setAlgorithm)
    LocaleService.start()
    authenticate()
  }, [])

  return (
    <ConfigProvider theme={{
      algorithm: getThemeAlgorithm(algorithm),
      token: {
        colorPrimary: "#0C4FFF",
        colorText: "var(--text)",
        borderRadius: 8,
        fontFamily: "Poppins, sans-serif",
        fontSize: 13
      },
      components: {
        Button: {
          colorBgContainer: "var(--blue-3)",
          colorText: "var(--blue)"
        },
        Card: {
          colorBgContainer: "transparent",
          borderRadius: 8,
        },
        Input: {
          colorBgContainer: "var(--body-2)",
          colorBorder: "var(--border)",
          colorText: "var(--text)",
          colorTextPlaceholder: "var(--text-2)",
          colorBgBase: "blue"
        },
        Select: {
          colorBgContainer: "var(--body-2)",
          colorBorder: "var(--border)",
          colorText: "var(--text)",
          colorTextPlaceholder: "var(--text-2)",
          colorBgBase: "blue",
          colorBgElevated: "var(--body)",
        },
        Table: {
          colorBgContainer: "transparent"
        }
      }
    }}>
      {loading ? (
        <Suspense />
      ) : (
        <Routes />
      )}
    </ConfigProvider >
  )
}

function getThemeAlgorithm(selectedTheme: string) {
  if (selectedTheme === "dark") return theme.darkAlgorithm
  return theme.defaultAlgorithm
}

const root = createRoot(document.getElementById('root')!)
root.render(<App />)