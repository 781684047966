import { hookstate } from '@hookstate/core'
import { Models } from '@roberty/models'

type StateType = {
	loadingZipCode: boolean
	loading: boolean
	workspace: Models.Customer
	errors: Record<string, string>
	activeTab: string
}

export const LocalState = hookstate({
	activeTab: `overview`,
	loading: false,
	errors: {},
	workspace: {}
} as StateType)