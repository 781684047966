import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Loading from "src/components/loading"

import Usage from "src/components/usage"
import presentConnectionErrorMessage from "src/lib/presentConnectionErrorMessage"
import RestClient from "src/services/rest"

const WorkspaceOverview: React.FC = () => {
  const { partnerId, workspaceId } = useParams()
  const [loading, setLoading] = useState(true)
  const [features, setFeatures] = useState<Feature[]>([])

  useEffect(() => {
    fetchUsage(partnerId, workspaceId)
      .then(setFeatures)
      .catch(presentConnectionErrorMessage)
      .finally(() => setLoading(false))
  }, [])

  if (loading) return (
    <Loading />
  )

  return (
    <div>
      {features.map((feature, index) => (
        <Usage
          key={index}
          feature={feature.feature}
          available={feature.max}
          used={feature.amount}
        />
      ))}
    </div>
  )
}

async function fetchUsage(partnerId: string, workspaceId: string) {
  return await RestClient.Workspace.Usage.get(partnerId, workspaceId)
}

type Feature = Awaited<ReturnType<typeof fetchUsage>>[0]

export default WorkspaceOverview