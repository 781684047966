import React from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"

import ErrorPage from "./error"
import NotFoundPage from "./notFound"
import DashboardPage from "./dashboard"
import WorkspacesPage from "./workspaces"
import SettingsPage from "./settings"
import SelectPartnerPage from "./selectPartner"
import RealtimePage from "./realtime"
import WorkspacePage from "./workspaces/[workspaceId]"
import SettingsUsersPage from "./settings/users"
import SettingsPreferencesPage from "./settings/preferences"
import SettingsInviteUserPage from "./settings/users/invite"
import WorkspaceOverview from "./workspaces/[workspaceId]/overview"
import WorkspaceRobots from "./workspaces/[workspaceId]/robots"
import WorkspaceDevices from "./workspaces/[workspaceId]/devices"
import WorkspaceUsers from "./workspaces/[workspaceId]/users"
import WorkspaceEdit from "./workspaces/[workspaceId]/edit"
import WorkspaceCreate from "./workspaces/create"

const routes = [
  {
    path: "/:partnerId",
    element: <DashboardPage />
  },
  {
    path: "/:partnerId/dashboard",
    element: <DashboardPage />
  },
  {
    path: "/:partnerId/workspaces",
    element: <WorkspacesPage />
  },
  {
    path: "/:partnerId/workspaces/create",
    element: <WorkspaceCreate />
  },
  {
    path: "/:partnerId/workspaces/:workspaceId",
    element: <WorkspacePage />,
    children: [
      {
        path: "overview",
        element: <WorkspaceOverview />
      },
      {
        path: "robots",
        element: <WorkspaceRobots />
      },
      {
        path: "devices",
        element: <WorkspaceDevices />
      },
      {
        path: "users",
        element: <WorkspaceUsers />
      },
      {
        path: "edit",
        element: <WorkspaceEdit />
      },
    ]
  },
  {
    path: "/:partnerId/realtime",
    element: <RealtimePage />
  },
  {
    path: "/:partnerId/settings",
    element: <SettingsPage />,
    children: [
      {
        path: "users",
        element: <SettingsUsersPage />,
        children: [
          {
            path: "invite",
            element: <SettingsInviteUserPage />
          }
        ]
      },
      {
        path: "preferences",
        element: <SettingsPreferencesPage />
      }
    ]
  },
]

const router = createBrowserRouter([
  {
    path: "/",
    element: <SelectPartnerPage />,
    errorElement: <NotFoundPage />
  },
  ...routes.map(route => ({
    ...route,
    errorElement: <ErrorPage />
  }))
])

const Routes: React.FC = () => (
  <RouterProvider
    router={router}
  />
)

export default Routes