import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import RestClient from "src/services/rest"
import StatisticsCard, { VariationType } from "../statisticsCard"

const DashboardActiveRobots: React.FC = () => {
  const { partnerId } = useParams()
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [variation, setVariation] = useState(0)
  const [variationType, setVariationType] = useState<VariationType>("none")

  useEffect(() => {
    fetchActiveRobots(partnerId)
      .then((workedHours) => {
        setTotal(workedHours.total)
        setVariation(workedHours.variation)
        setVariationType(workedHours.variationType as VariationType)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <StatisticsCard
      title="Active robots"
      data={total}
      variation={variation}
      variationType={variationType}
      loading={loading}
    />
  )
}

async function fetchActiveRobots(partnerId: string) {
  return await RestClient.Dashboard.ActiveRobots.get(partnerId)
}

export default DashboardActiveRobots