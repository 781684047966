import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import RestClient from "src/services/rest"
import StatisticsCard, { VariationType } from "../statisticsCard"
import formatDuration from "src/lib/formatDuration"

const multiplier = 6

const DashboardSavedHours: React.FC = () => {
  const { partnerId } = useParams()
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [variation, setVariation] = useState(0)
  const [variationType, setVariationType] = useState<VariationType>("none")

  useEffect(() => {
    fetchSavedHours(partnerId)
      .then((workedHours) => {
        setTotal(workedHours.total * multiplier)
        setVariation(workedHours.variation * multiplier)
        setVariationType(workedHours.variationType as VariationType)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <StatisticsCard
      title="Saved hours of human labor"
      data={formatDuration(total, "HH[h]mm[m]")}
      variation={formatDuration(variation, "HH[h]mm[m]")}
      variationType={variationType}
      variationColor="var(--blue)"
      loading={loading}
    />
  )
}

async function fetchSavedHours(partnerId: string) {
  return await RestClient.Dashboard.WorkedHours.get(partnerId)
}

export default DashboardSavedHours